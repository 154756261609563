import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/Home/HomeView.vue";
import AuthenticationView from "@/views/Authentication/AuthenticationView.vue";
import ProfileView from "@/views/Profile/ProfileView.vue";
import AdministrationView from "@/views/Administration/AdministrationView.vue";
import UnitManagementView from "@/views/Administration/UnitManagementView.vue";
import IngredientManagementView from "@/views/Administration/IngredientManagementView.vue";
import RecipesView from "@/views/Recipes/RecipesView.vue";
import RecipeFormView from "@/views/Recipes/RecipeFormView.vue"
import RecipeDisplayView from "@/views/Recipes/RecipeDisplayView.vue"

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView
    },
    {
        path: "/connection",
        name: "connection",
        component: AuthenticationView,
        meta: {
            register: false
        }
    },
    {
        path: "/register",
        name: "register",
        component: AuthenticationView,
        meta: {
            register: true
        }
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfileView
    },
    {
        path: "/admin",
        name: "admin",
        redirect: { name: "manage-ingredients" },
        component: AdministrationView,
        children: [
            {
                path: "ingredients",
                name: "manage-ingredients",
                component: IngredientManagementView
            },
            {
                path: "units",
                name: "manage-units",
                component: UnitManagementView
            }
        ]
    },
    {
        path: "/recipes",
        name: "recipes",
        component: RecipesView
    },
    {
        path: "/recipes/create",
        name: "recipes-create",
        component: RecipeFormView,
        meta: {
            edit: false
        }
    },
    {
        path: "/recipes/:id(\\d+)",
        name: "recipes-display",
        component: RecipeDisplayView
    },
    {
        path: "/recipes/edit/:id(\\d+)",
        name: "recipes-edit",
        component: RecipeFormView,
        meta: {
            edit: true
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
