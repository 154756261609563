<template>
  <v-container class="my-4">
    <v-row>
      <v-col>
        <h2 class="text-center">
          Profile
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          readonly
          :loading="user == null"
          label="FirstName"
          :value="user?.firstName"
        />
      </v-col>
      <v-col>
        <v-text-field
          readonly
          :loading="user == null"
          label="LastName"
          :value="user?.lastName"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          readonly
          :loading="user == null"
          label="Email"
          :value="user?.email"
        />
      </v-col>
      <v-col>
        <v-text-field
          readonly
          :loading="user == null"
          label="Role"
          :value="user?.role"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
        class="d-flex align-center justify-space-between"
      >
        <v-dialog
          v-model="colorDialogOpen"
          max-width="450"
        >
          <template #activator="{ on }">
            <span>Main Color</span>
            <div
              :style="{'width': '70px', 'height': '35px', 'background-color': (user?.preferences?.mainColor ?? '#d2a319') }"
              v-on="on"
            />
          </template>
          <v-card class="pt-3">
            <v-card-title class="justify-center">
              <span class="text-h5 text-center">Main Color</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    class="d-flex justify-center"
                    cols="12"
                  >
                    <v-color-picker
                      :value="user?.preferences?.mainColor"
                      @input="updateColor"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="cancel"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="saveMainColor"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: "ProfileView",
    data() {
      return {
        user: {
          preferences: {
            mainColor: this.$store.state.mainColor
          }
        },
        colorDialogOpen: false
      };
    },
    created() {
      axios.get("/users/current").then((resp) => {
        this.user = resp.data.user;
        if (this.user.preferences.mainColor == null)
          this.user.preferences.mainColor = this.$store.state.mainColor;
      });
    },

    methods: {
      updateColor(event) {
        this.user.preferences.mainColor = event;
        this.$vuetify.theme.themes.light.primary = event;
      },
      cancel() {
        this.user.preferences.mainColor = this.$store.state.mainColor;
        this.$vuetify.theme.themes.light.primary = this.user.preferences.mainColor;
        this.colorDialogOpen = false;
      },
      saveMainColor() {
        axios.post(`/users/update/${this.$store.state.tokenPayload.userId}`, {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          preferences: {
            mainColor: this.user.preferences.mainColor
          }
        }).then(() => {
          this.$store.commit("setMainColor", this.user.preferences.mainColor);
          this.colorDialogOpen = false;
        })
      }
    }
  };
</script>
