<template>
  <div>
    <slot
      name="items"
      v-bind="{items, pageCount}"
    />
  </div>
</template>

<script>
  import axios from 'axios';
  import qs from "qs";

  export default {
    name: "GenericPaginator",
    model: {
      prop: 'page',
      event: 'change'
    },
    props: {
      countUrl: {
        type: String,
        required: true
      },
      paginateUrl: {
        type: String,
        required: true
      },
      columns: {
        type: Array,
        required: true
      },
      filter: {
        type: String,
        required: false,
        default: null
      },
      page: {
        type: Number,
        required: true
      },
      pageSize: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        items: [],
        total: 0
      }
    },
    computed: {
      countQueryParameters() {
        const query = {
          columns: this.columns,
          filter: null
        };
        if (this.doesFilterExists) query.filter = this.filter;

        return qs.stringify(query);
      },
      paginateQueryParameters() {
        const query = {
          columns: this.columns,
          filter: null,
          skip: this.page - 1,
          limit: this.pageSize
        };
        if (this.doesFilterExists) query.filter = this.filter;

        return qs.stringify(query);
      },
      doesFilterExists() {
        return !["", null].includes(this.filter);
      },
      pageCount() {
        return Math.ceil(this.total / this.pageSize)
      }
    },
    watch: {
      page() {
        this.fetchItems();
      },
      filter() {
        this.refreshResults();
      }
    },
    mounted() {
      this.refreshResults();
    },
    methods: {
      fetchCount() {
        return new Promise(resolve => {
          axios.get(`${this.countUrl}${this.countQueryParameters != "" ? "?" : ""}${
            this.countQueryParameters
          }`).then(resp => {
            this.total = resp.data.count;
            resolve();
          });
        });
      },
      fetchItems() {
        return new Promise(resolve => {
          axios.get(`${this.paginateUrl}${this.paginateQueryParameters != "" ? "?" : ""}${
            this.paginateQueryParameters
          }`).then(resp => {
            this.items = resp.data.items;
            resolve();
          })
        });
      },
      refreshResults() {
        this.fetchCount().then(()=> {
          this.fetchItems();
        });
      }
    }
  }
</script>
