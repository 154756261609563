<template>
  <v-dialog
    :value="value"
    max-width="500"
    @input="cancel"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="{ on, attrs }"
      />
    </template>
    <ValidationForm
      :url="url"
      :form="form"
      @submit="submit"
    >
      <template #content="{rules}">
        <v-card class="pt-3">
          <v-card-title class="justify-center">
            <span class="text-h5 text-center">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.name"
                    label="Name"
                    :rules="rules('name')"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="form.unitaryValue"
                    label="Unitary value"
                    :rules="rules('unitaryValue')"
                  />
                </v-col>
                <v-col cols="12">
                  <FetchSelect
                    v-model="form.metricId"
                    url="/units/metrics/all"
                    label="Metric"
                    :mapper="e => e.metrics"
                    :rules="rules('metricId')"
                    item-text="name"
                    item-value="id"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
            >
              {{ submitButtonText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ValidationForm>
  </v-dialog>
</template>

<script>
  import ValidationForm from "@/components/ValidationForm.vue";
  import FetchSelect from "@/components/FetchSelect.vue";
  export default {
    components: {
      ValidationForm,
      FetchSelect
    },

    props: {
      value: {
        type: Boolean,
        required: true
      },
      default: {
        type: Object,
        required: false,
        default: () => null
      },
      editMode: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data() {
      return {
        form: null
      };
    },

    computed: {
      title() {
        return this.editMode ? "Edit Ingredient" : "Add new Ingredient";
      },
      url() {
        return this.editMode ? `/ingredients/update/${this.form.id}` : "/ingredients/insert";
      },
      submitButtonText() {
        return this.editMode ? "Save" : "Confirm";
      }
    },
    
    watch: {
      default() {
        this.resetForm();
      }
    },

    created() {
      this.resetForm();
    },

    methods: {
      resetForm() {
        this.form = {
          name: null,
          unitaryValue: null,
          metricId: null,
          ...this.default
        };
      },
      cancel() {
        this.resetForm();
        this.$emit("input", false);
      },

      submit(event) {
        this.resetForm();
        this.$emit("input", false);
        this.$emit("submit", event);
      }
    }
  }
</script>