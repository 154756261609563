<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :class="{'on-hover': hover}"
      :elevation="hover ? 12 : 2"
      @click="goToRecipe"
    >
      <v-img
        height="250"
        :src="absoluteImageUrl ?? require('@/assets/default_meal.jpeg')"
      >
        <v-app-bar
          v-if="editable"
          flat
          color="rgba(0, 0, 0, 0)"
        >
          <v-spacer />
          <v-btn
            class="red"
            color="white"
            icon
            :height="40"
            :width="40"
            @click.stop="editRecipe"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-app-bar>
      </v-img>
      <v-card-title>
        {{ name }}<v-icon
          :title="privacy === 'public' ? 'public' : 'private'"
          class="ms-2"
          v-text="privacy === 'public' ? 'mdi-earth' : 'mdi-eye-lock'"
        />
      </v-card-title>
      <v-row>
        <v-col>
          <v-avatar class="ms-4 me-3">
            <v-img
              v-if="avatar != null"
              :src="`data:image/png;base64,${avatar}`"
            />
            <v-icon v-else>
              mdi-account-circle
            </v-icon>
          </v-avatar>
          {{ user?.nickname ?? "" }}
        </v-col>
      </v-row>
      <v-card-text>
        {{ lore ?? "No description" }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
  import axios from 'axios';
  export default {
    props: {
      id: {
        type: Number,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      lore: {
        type: String,
        required: false,
        default: null
      },
      imageUrl: {
        type: String,
        required: false,
        default: null
      },
      privacy: {
        type: String,
        required: true
      },
      userId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        avatar: null,
        user: null
      }
    },
    computed: {
      absoluteImageUrl() {
        return this.imageUrl ? `/api/images/get/${this.imageUrl}` : null;
      },
      editable() {
        return this.$store.getters.isAdmin || this.$store.state.tokenPayload.userId === this.userId;
      }
    },
    created() {
      axios.get(`/users/avatar/${this.userId}`).then((response) => {
        this.avatar = response.data.image;
      });
      axios.get(`/users/one/${this.userId}`).then((response) => {
        this.user = response.data.user;
      });
    },
    methods: {
      goToRecipe() {
        this.$router.push({name: "recipes-display", params: {id: this.id}});
      },
      editRecipe() {
        this.$router.push({name: "recipes-edit", params: {id: this.id}})
      }
    }
  }
</script>

<style scoped>

.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.75;
 }

</style>