<template>
  <v-container class="my-4">
    <v-row class="align-center px-3 px-md-0">
      <v-col
        cols="12"
        md="3"
      >
        <h1 class="text-center text-md-start">
          Recipes
        </h1>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filter"
          label="Search for a specific recipe"
        />
      </v-col>
      <v-col
        cols="3"
        md="1"
        class="d-flex justify-end"
      >
        <v-btn
          class="primary"
          :to="{name: 'recipes-create'}"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
    <GenericPaginator
      v-model="page"
      count-url="/recipes/count"
      paginate-url="/recipes/pagination"
      :filter="filter"
      :columns="['name']"
      :page-size="12"
    >
      <template #items="{items, pageCount}">
        <v-sheet color="grey lighten-3">
          <v-row class="px-3 mt-8">
            <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <RecipeListItem
                v-bind="item"
              />
            </v-col>
          </v-row>
          <v-row class="pt-5">
            <v-col>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  @input="onPaginationUpdate"
                />
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </template>
    </GenericPaginator>
  </v-container>
</template>

<script>
  import RecipeListItem from '@/components/RecipesView/RecipeListItem.vue'
  import GenericPaginator from '@/components/GenericPaginator.vue'
  export default {
    components: {
      GenericPaginator,
      RecipeListItem
    },
    data() {
      return {
        filter: null,
        page: 1,
      };
    },
    created() {
      const queryPage = parseInt(this.$route.query.page);
      this.page = isNaN(queryPage) ? 1 : queryPage;
    },

    methods: {
      onPaginationUpdate() {
        this.$router.push({ path: 'recipes', query: { page: this.page }});
      }
    }
  }
</script>
