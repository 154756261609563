import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import axios from "axios";

Vue.config.productionTip = false;

axios.defaults.baseURL = "/api";
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            store.commit("removeJwtToken");
            router.push({ name: "connection" });
        }
        return Promise.reject(error);
    }
);

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");
