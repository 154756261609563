<template>
  <v-autocomplete
    ref="autocomplete"
    :value="value"
    v-bind="$attrs"
    :items="items"
    :search-input="filter"
    :cache-items="true"
    @input="updateInput"
    @update:search-input="updateFilter"
  >
    <template slot="no-data">
      <slot name="no-data" />
    </template>
  </v-autocomplete>
</template>

<script>
  import axios from 'axios';
  export default {
    name: "FetchAutoComplete",
    props: {
      url: {
        type: String,
        required: true
      },
      mapper: {
        type: Function,
        required: false,
        default: e => e
      },
      filter: {
        type: String,
        required: false,
        default: null
      },
      value: {
        type: [String, Array],
        required: true
      }
    },
    data() {
      return {
        items: []
      };
    },
    watch: {
      url() {
        this.updateItems();
      },
      // This is a workaround to update cached items when selected items is mutated elsewhere
      value() {
        this.$refs['autocomplete'].cachedItems = [...this.items, ...this.value]
      }
    },
    created() {
      this.updateItems();
    },
    methods: {
      updateItems() {
        axios.get(this.url).then(resp => {
          this.items = this.mapper(resp.data);
        });
      },
      updateFilter(value) {
        this.$emit("update:filter", value);
      },
      updateInput(value) {
        this.$emit('input', value);
        this.$emit('update:filter', null);
      }
    }
  }
</script>