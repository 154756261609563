<template>
  <v-select
    v-bind="$attrs"
    :items="items"
    @input="$emit('input', $event)"
  />
</template>

<script>
  import axios from 'axios';
  export default {
    name: "FetchSelect",
    props: {
      url: {
        type: String,
        required: true
      },
      mapper: {
        type: Function,
        required: false,
        default: e => e
      }
    },
    data() {
      return {
        items: []
      };
    },
    watch: {
      url() {
        this.updateItems();
      }
    },
    mounted() {
      this.updateItems();
    },
    methods: {
      updateItems() {
        axios.get(this.url).then(resp => {
          this.items = this.mapper(resp.data);
        });
      }
    }
  }
</script>