<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2 class="text-center">
          Units Management
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PaginatedTable
          ref="unitsTable"
          count-url="/units/count"
          pagination-url="/units/pagination"
          :headers="headers"
          @add-item="addUnit"
          @edit-item="editUnit"
          @delete-item="deleteUnit"
        />
        <UnitForm
          v-model="createDialog"
          @submit="refreshUnits"
        />
        <UnitForm
          v-model="editDialog"
          :edit-mode="true"
          :default="editingUnit"
          @submit="refreshUnits"
        />
        <DeleteUnit
          v-if="deletingUnit != null"
          v-model="deleteDialog"
          :unit="deletingUnit"
          @submit="refreshUnits"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UnitForm from "@/components/UnitManagementView/UnitForm.vue";
  import PaginatedTable from "@/components/PaginatedTable.vue";
  import DeleteUnit from "@/components/UnitManagementView/DeleteUnit.vue";

  export default {
    components: {
      UnitForm,
      PaginatedTable,
      DeleteUnit
    },

    data() {
      return {
        createDialog: false,
        editDialog: false,
        editingUnit: null,
        deleteDialog: false,
        deletingUnit: null
      };
    },

    computed: {
      headers() {
        return [
          {
            text: "Unit",
            align: "start",
            value: "name",
            sortable: false,
            searchable: true
          },
          { text: "Symbol", value: "symbol", sortable: false, searchable: true },
          { text: "Default", value: "default", sortable: false },
          { text: "Multiplier", value: "multiplier", sortable: false },
          { text: "Metric", value: "metric.name", sortable: false, searchable: true }
        ];
      }
    },

    methods: {
      addUnit() {
        this.createDialog = true;
      },
      editUnit(unit) {
        this.editingUnit = unit;
        this.editDialog = true;
      },

      deleteUnit(unit) {
        this.deletingUnit = unit;
        this.deleteDialog = true;
      },
      
      refreshUnits() {
        this.$refs.unitsTable.updateItems();
      }
    }
  };
</script>
