var preventPageLeaveMixin = {

    data() {
        return {
            dirty: false
        };
    },

    created() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
    },

    beforeDestroy() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },

    methods: {
        confirmLeave() {
            return window.confirm('Do you really want to leave? you have unsaved changes!');
        },

        confirmStayInDirtyForm() {
            return this.dirty && !this.confirmLeave();
        },

        beforeWindowUnload(e) {
            if (this.confirmStayInDirtyForm()) {
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        }
    },

    beforeRouteLeave (_to, _from, next) {
        if (this.confirmStayInDirtyForm()){
          next(false)
        } else {
          next()
        }
      },
}

export default preventPageLeaveMixin;