<template>
  <Editor
    :value="content"
    toolbar="undo redo | blocks | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist"
    plugins="lists link image table code help wordcount"
    :init="{height: 700}"
    @input="$emit('input', $event)"
  />
</template>

<script>
  import Editor from '@tinymce/tinymce-vue'
    
  export default {
    components: {
      Editor
    },

    model: {
      prop: "content"
    },

    props: {
      content: {
        type: String,
        required: false,
        default: null
      }
    }
  }
</script>