<template>
  <v-dialog
    :value="value"
    max-width="500"
    @input="$emit('input', $event)"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="{ on, attrs }"
      />
    </template>
    <v-card class="pt-3">
      <v-card-title class="justify-center">
        <span class="text-h5 text-center">Delete {{ ingredient.name }} Ingredient</span>
      </v-card-title>
      <v-card-text>
        Are you sure you want to delete this ingredient ?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('input', false)"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="deleteIngredient"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios from "axios";
  export default {
    props: {
      value: {
        type: Boolean,
        required: true
      },
      ingredient: {
        type: Object,
        required: true
      }
    },
    
    methods: {
      deleteIngredient() {
        axios.post(`/ingredients/delete/${this.ingredient.id}`).then(() => {
          this.$emit("input", false);
          this.$emit("submit");
        })
      }
    }
  }
</script>