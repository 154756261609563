<template>
  <div>
    <v-data-table
      :options.sync="options"
      :headers="headersWithActions"
      :items="items"
      :loading="loading"
      :server-items-length="totalItems"
    >
      <template #top>
        <div class="d-flex align-center">
          <v-text-field
            v-if="searchableColumns.length > 0"
            v-model="filter"
            label="Search"
            class="mx-4"
          />
          <v-btn
            color="primary"
            class="ms-3"
            @click="$emit('add-item')"
          >
            Add
          </v-btn>
        </div>
      </template>
      <template
        v-for="header in headers"
        #[`item.${header.value}`]="{ item, value }"
      >
        <template v-if="header.formatter != null">
          {{ header.formatter({item, value}) }}
        </template>
        <template v-else-if="typeof value === 'boolean'">
          <v-simple-checkbox
            :key="header.name"
            :value="value"
            disabled
          />
        </template>
        <template v-else>
          {{ value }}
        </template>
      </template>
      <template #item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="$emit('edit-item', item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="$emit('delete-item', item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn
          color="primary"
          @click="filter = ''"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from "axios";
  import qs from "qs";
  export default {
    props: {
      countUrl: {
        type: String,
        required: true
      },
      paginationUrl: {
        type: String,
        required: true
      },
      headers: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        filter: "",
        options: {},
        items: [],
        loading: true,
        totalItems: 0
      };
    },
    computed: {
      searchableColumns() {
        return this.headers
          .filter((header) => header.searchable != null && header.searchable)
          .map((header) => header.value);
      },
      doesFilterExists() {
        return !["", null].includes(this.filter);
      },
      countQueryParameters() {
        const query = {};
        if (this.searchableColumns.length > 0) query.columns = this.searchableColumns;
        if (this.doesFilterExists) query.filter = this.filter;

        return qs.stringify(query);
      },
      paginationQueryParameters() {
        const { page, itemsPerPage } = this.options;
        const query = {
          limit: itemsPerPage,
          skip: page - 1
        };

        if (this.searchableColumns.length > 0) query.columns = this.searchableColumns;
        if (this.doesFilterExists) query.filter = this.filter;

        return qs.stringify(query);
      },
      headersWithActions() {
        return [...this.headers, { text: "Actions", value: "actions", sortable: false }];
      }
    },
    watch: {
      options: {
        handler() {
          this.updateItems();
        },
        deep: true
      },
      filter() {
        this.updateItems();
      }
    },
    methods: {
      updateItems() {
        this.loading = true;
        Promise.all([
          axios.get(
            `${this.countUrl}${this.countQueryParameters != "" ? "?" : ""}${
              this.countQueryParameters
            }`
          ),
          axios.get(`${this.paginationUrl}?${this.paginationQueryParameters}`)
        ]).then((responses) => {
          this.totalItems = responses[0].data.count;
          this.items = responses[1].data.items;
          this.loading = false;
        });
      }
    }
  };
</script>
