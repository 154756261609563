<template>
  <v-app class="fill-height">
    <v-app-bar
      app
      clipped-left
      color="primary"
      dark
    >
      <router-link
        :to="{ name: 'home' }"
        class="d-flex align-center"
      >
        <v-img
          alt="Meals Logo"
          class="shrink mr-2"
          contain
          src="@/assets/icon.png"
          transition="scale-transition"
          width="40"
        />

        <h1 class="white--text">
          Meals.io
        </h1>
      </router-link>
      
      <router-link
        :to="{ name: 'recipes' }"
        class="align-center white--text ps-5 d-none d-md-flex"
      >
        Recipes
      </router-link>

      <router-link
        :to="{ name: 'home' }"
        class="align-center white--text ps-5 d-none d-md-flex"
      >
        Planning
      </router-link>

      <router-link
        :to="{ name: 'home' }"
        class="align-center white--text ps-5 d-none d-md-flex"
      >
        Shopping
      </router-link>

      <v-spacer />

      <ProfileMenu />
    </v-app-bar>

    <v-main class="fill-height">
      <div class="fill-height overflow-y-auto">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
  import ProfileMenu from "@/components/ProfileMenu.vue";

  export default {
    name: "App",
    components: {
      ProfileMenu
    },
    created() {
      this.$store.dispatch("initialize");
    }
  };
</script>

<style scoped>
/* This remove the active route css opacity for router v-btn */
.v-btn--active.no-active:not(:focus):not(:hover)::before {
  opacity: 0 !important;
}

/* This remove the upgrade button for tinymce editor */
>>> .tox-promotion {
  display: none;
}

</style>
