<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2 class="text-center">
          Welcome to Meals.io !
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: "HomeView"
  };
</script>
