<template>
  <v-container>
    <v-row>
      <v-col>
        <ValidationForm
          :url="url"
          :form="form"
          :multi-form="true"
          @submit="submit"
        >
          <template #content="{rules}">
            <v-card
              class="pt-3"
              elevation="3"
            >
              <v-card-title class="justify-center">
                <span class="text-h5 text-center">{{ title }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="form.name"
                        label="Name *"
                        :rules="rules('name')"
                        required
                        @input="dirty = true"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="form.serves"
                        label="Serves *"
                        :rules="rules('serves')"
                        required
                        type="number"
                        @input="dirty = true"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="form.privacy"
                        label="Privacy *"
                        :items="['private', 'public']"
                        :rules="rules('privacy')"
                        required
                        @input="dirty = true"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="align-center">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-file-input
                        v-model="form.image"
                        show-size
                        label="Image"
                        accept="image/*"
                        :rules="rules('image')"
                        @change="dirty = true"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-img
                        contain
                        :height="300"
                        :src="uploadedImageUrl"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="form.originalUrl"
                        label="Original link"
                        :rules="rules('originalUrl')"
                        @input="dirty = true"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h2>Ingredients</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex">
                      <IngredientsSelector
                        :ingredient-filter.sync="ingredientFilter"
                        :rules="rules('ingredients')"
                        :selected-ingredients="selectedIngredients"
                        @input="updateSelectedIngredients"
                      />
                      <div class="d-flex align-center">
                        <v-btn
                          class="ms-2"
                          icon
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="selectedIngredients.length > 0"
                    class="ps-md-6"
                  >
                    <v-col>
                      <h3>Quantities</h3>
                    </v-col>
                  </v-row>
                  <v-row class="ps-md-6">
                    <v-col>
                      <ul>
                        <li
                          v-for="(recipeIngredient, i) in form.ingredients"
                          :key="recipeIngredient.ingredientId"
                          class="mb-4 mb-md-0"
                        >
                          <RecipeIngredient
                            :name="recipeIngredient.name"
                            :metric-id="recipeIngredient.metricId"
                            :amount.sync="recipeIngredient.value"
                            :unit-id.sync="recipeIngredient.unitId"
                            :rules="rules('ingredients', true)(i)"
                          />
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h2>Editor</h2>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>Write your instructions here</p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <TinyEditor v-model="form.recipeHTML" @input="dirty = true" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="cancel"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  type="submit"
                >
                  {{ submitButtonText }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </ValidationForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ValidationForm from '@/components/ValidationForm.vue'
  import TinyEditor from '@/components/TinyEditor.vue'
  import RecipeIngredient from '@/components/RecipesView/RecipeIngredient.vue'
  import IngredientsSelector from '@/components/RecipesView/IngredientsSelector.vue'
  import axios from 'axios'
  import preventPageLeaveMixin from '@/mixins/preventPageLeave'
  export default {
    name: "RecipeFormView",
    mixins: [preventPageLeaveMixin],
    components: { IngredientsSelector, ValidationForm, TinyEditor, RecipeIngredient },
    data() {
      return {
        form: {
          name: "",
          serves: null,
          privacy: null,
          image: null,
          imageUrl: null,
          originalUrl: null,
          recipeHTML: null,
          userId: null,
          ingredients: []
        },
        ingredientFilter: null,
        selectedIngredients: [],
      }
    },
    computed: {
      editMode() {
        return this.$route.meta.edit;
      },
      title() {
        return this.editMode ? "Edit an existing recipe" : "Add a new recipe";
      },
      url() {
        return this.editMode ? `/recipes/update/${this.form.id}` : "/recipes/insert";
      },
      submitButtonText() {
        return this.editMode ? "Save" : "Confirm";
      },
      uploadedImageUrl() {
        return this.form.image != null ? URL.createObjectURL(this.form.image) : (this.form.imageUrl != null ? `/api/images/get/${this.form.imageUrl}` : null);
      }
    },
    created() {
      this.form.userId = this.$store.state.tokenPayload.userId;
      if (this.editMode) {
        axios.get(`/recipes/one/${this.$route.params.id}`).then(resp => {
          const ingredients = resp.data.recipe.ingredients;
          delete resp.data.recipe.ingredients;
          this.form = resp.data.recipe;
          this.form.ingredients = ingredients.map(recipeIngredient => ({
            ingredientId: recipeIngredient.ingredient.id,
            name: recipeIngredient.ingredient.name,
            value: recipeIngredient.value,
            metricId: recipeIngredient.ingredient.metricId,
            unitId: recipeIngredient.unit?.id ?? null
          }));
          this.selectedIngredients = ingredients.map(recipeIngredient => ({
            id: recipeIngredient.ingredient.id,
            name: recipeIngredient.ingredient.name,
            metricId: recipeIngredient.ingredient.metricId,
            value: recipeIngredient.value,
            unitId: recipeIngredient.unit?.id ?? null
          }));
        });
      }
    },
    methods: {
      cancel() {
        this.$router.back();
      },
      submit(resp) {
        if (resp.status === 200) {
          this.dirty = false;
          this.$router.push({name: "recipes"});
        }
      },
      updateSelectedIngredients(ingredients) {
        this.dirty = true;
        this.selectedIngredients = ingredients;

        // Add new elements
        for (const ingredient of ingredients) {
          if (!this.form.ingredients.some(recipeIngredient => recipeIngredient.ingredientId === ingredient.id)) {
            this.form.ingredients.push({
              ingredientId: ingredient.id,
              name: ingredient.name,
              metricId: ingredient.metricId
            });
          }
        }

        const updatedRecipeIngredients = []

        // Filter removed elements
        for (const recipeIngredient of this.form.ingredients) {
          if (ingredients.some(ingredient => ingredient.id === recipeIngredient.ingredientId)) {
            updatedRecipeIngredients.push(recipeIngredient);
          }
        }

        this.form.ingredients = updatedRecipeIngredients;
      }
    },
  }
</script>