import Vue from "vue";
import Vuetify from '../plugins/vuetify'
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mainColor: "#d2a319",
        jwtToken: null,
        tokenPayload: null
    },
    getters: {
        isConnected(state) {
            return state.jwtToken != null;
        },
        isAdmin(state) {
            return state.tokenPayload?.role === "admin";
        }
    },
    mutations: {
        setJwtToken(state, token) {
            if (token == null) return;
            state.jwtToken = token;
            axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("jwt-token", token);
            const payload = JSON.parse(atob(token.split(".")[1]));
            state.tokenPayload = payload;
        },
        removeJwtToken(state) {
            state.jwtToken = null;
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem("jwt-token");
        },
        setMainColor(state, color) {
            if (color == null) {
                localStorage.removeItem("mainColor");
                Vuetify.framework.theme.themes.light.primary = "#d2a319";
                return;
            }
            state.mainColor = color;
            Vuetify.framework.theme.themes.light.primary = color;
            localStorage.setItem("mainColor", color);
        }
    },
    actions: {
        initialize(context) {
            context.commit("setJwtToken", localStorage.getItem("jwt-token"));
            context.commit("setMainColor", localStorage.getItem("mainColor"));
            axios.get("/users/current").then(resp => {
                context.commit("setMainColor", resp.data.user.preferences.mainColor)
            });
        }
    },
    modules: {}
});
