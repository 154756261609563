<template>
  <v-form
    ref="form"
    @submit.prevent="submit"
  >
    <slot
      name="content"
      :rules="formRules"
    />
  </v-form>
</template>

<script>
  import axios from "axios";

  export default {
    name: "ValidationForm",
    props: {
      url: {
        type: String,
        required: true
      },
      form: {
        type: Object,
        required: true
      },
      multiForm: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        rules: []
      };
    },
    computed: {
      formRules() {
        const computeFormRules = function(rules) {
          // Sub mode is used when we want to use deep rules for nested and array objects.
          return (name, sub=false) => {
            let subFormRules = [];
            for (const rule of rules) {
              if (rule.property !== name) continue;
              if (rule.cause instanceof Object) {
                // If rule is a subset of rules
                if (sub) {
                  // Then we must return the rules matching the provided index
                  subFormRules = (index) => {
                    if (rule.cause[index] != null) {
                      return computeFormRules(rule.cause[index]);
                    }
                    // If no rules are found for this index, we must return null
                    else return null;
                  }
                  break;
                }
              } else {
                if (!sub)
                  subFormRules.push(() => rule.cause);
              }
            }
            // If we are looking for sub rules and no errors were found, every index will return null (for no validation rules)
            if (sub && Array.isArray(subFormRules))
              return () => null;
            return subFormRules;
          };
        }

        return computeFormRules(this.rules);
      },

      requestOptions() {
        if (this.multiForm) 
          return {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            formSerializer: {
              indexes: true
            }
          };
        return {};
      }
    },
    methods: {
      submit() {
        // We need to clear the rules before afecting new one to force vuetify to update text-field validation
        this.rules = [];

        axios
          .post(this.url, this.form, this.requestOptions)
          .then((resp) => {
            this.$emit("submit", resp);
          })
          .catch((reason) => {
            if (reason.response.status === 400) {
              this.rules = reason.response.data.causes;
              this.$refs.form.validate();
            } else {
              this.$emit("submit", reason.response);
            }
          });
      }
    }
  };
</script>
