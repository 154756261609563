<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <v-img
      v-if="recipe"
      class="d-flex align-center"
      height="25vh"
      :src="absoluteImageUrl ?? require('@/assets/default_meal.jpeg')"
      gradient="to top, rgba(200,200,200,.3), rgba(20,20,20,.9)"
    >
      <h1 class="white--text d-flex justify-center">
        {{ recipe.name }}
        <v-icon
          :title="recipe.privacy === 'public' ? 'public' : 'private'"
          class="white--text ms-4"
          v-text="recipe.privacy === 'public' ? 'mdi-earth' : 'mdi-eye-lock'"
        />
      </h1>
    </v-img>
    <v-container
      v-if="recipe != null"
      class="pt-5"
    >
      <template v-if="recipe.lore != null">
        <v-row>
          <v-col>
            <h2>Description</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ recipe.lore }}</p>
          </v-col>
        </v-row>
      </template>
      <template v-if="recipe.originalUrl">
        <v-row>
          <v-col>
            <h2>Link to original recipe</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a
              target="_blank"
              :href="recipe.originalUrl"
            >{{ recipe.originalUrl }}</a>
          </v-col>
        </v-row>
      </template>
      <v-row>
        <v-col>
          <h2>Ingredients</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p v-if="recipe.ingredients.length === 0">
            No ingredients
          </p>
          <ul v-else>
            <li
              v-for="recipeIngredient in recipe.ingredients"
              :key="recipeIngredient.id"
            >
              <template v-if="recipeIngredient.unit != null">
                <span>{{ recipeIngredient.ingredient.name }}</span>
                <span>, {{ recipeIngredient.value }}</span>
                <span>{{ recipeIngredient.unit.symbol }}</span>
              </template>
              <template v-else>
                <span>{{ recipeIngredient.value }} </span>
                <span>{{ recipeIngredient.ingredient.name }}</span>
              </template>
            </li>
          </ul>
        </v-col>
      </v-row>
      <template v-if="recipe.recipeHTML != null">
        <v-row>
          <v-col>
            <h2>Recipe</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div
              class="ps-md-8"
              v-html="recipe.recipeHTML"
            />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: "RecipeDisplayView",
    data() {
      return {
        recipe: null
      }
    },

    computed: {
      absoluteImageUrl() {
        return this.recipe?.imageUrl ? `/api/images/get/${this.recipe.imageUrl}` : null;
      },
    },

    created() {
      axios.get(`/recipes/one/${this.$route.params.id}`).then(resp => {
        this.recipe = resp.data.recipe;
      });
    }
  }
</script>