<template>
  <FetchAutoComplete
    :value="selectedIngredients"
    multiple
    chips
    deletable-chips
    attach
    :filter="ingredientFilter"
    :url="ingredientSelectUrl"
    :mapper="e => e.items"
    item-text="name"
    item-value="id"
    return-object
    :rules="rules"
    @update:filter="$emit('update:ingredientFilter', $event)"
    @input="$emit('input', $event)"
  >
    <template slot="no-data">
      <v-list>
        <v-list-item-group @change="createIngredient">
          <v-list-item>
            <v-list-item-action>
              <v-icon>
                mdi-plus
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              Add new ingredient {{ ingredientFilter }}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <IngredientForm
        v-model="createDialog"
        :default="defaultIngredient"
        @submit="onIngredientCreated"
      />
    </template>
  </FetchAutoComplete>
</template>

<script>
  import FetchAutoComplete from '@/components/FetchAutoComplete.vue'
  import IngredientForm from '../IngredientManagementView/IngredientForm.vue'

  export default {
    components: { FetchAutoComplete, IngredientForm },
    props: {
      rules: {
        type: Array,
        required: true
      },
      ingredientFilter: {
        type: String,
        required: false,
        default: null
      },
      selectedIngredients: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        createDialog: false    
      };
    },
    computed: {
      ingredientSelectUrl() {
        return `/ingredients/pagination?skip=0&limit=10&columns[]=name&filter=${this.ingredientFilter ?? ""}`
      },
      defaultIngredient() {
        return {
          name: (this.ingredientFilter?.length ?? 0) > 0 ? this.ingredientFilter[0].toUpperCase() + this.ingredientFilter.substring(1) : ""
        };
      }
    },

    methods: {
      createIngredient() {
        this.createDialog = true
      },
      
      onIngredientCreated({data}) {
        this.$emit("input", [...this.selectedIngredients, data.ingredient]);
        this.$emit("update:ingredientFilter", null);
      }
    }
  }
</script>
