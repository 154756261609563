<template>
  <v-btn
    v-if="!isUserConnected"
    active-class="no-active"
    :to="{ name: 'connection' }"
  >
    Login
  </v-btn>
  <v-menu
    v-else
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        icon
        x-large
        color="white"
        v-on="on"
      >
        <v-avatar>
          <v-img
            v-if="avatar != null"
            :src="`data:image/png;base64,${avatar}`"
          />
          <v-icon v-else>
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-card
      class="mx-auto"
      max-width="300"
      tile
    >
      <v-list dense>
        <v-list-item
          v-for="(item, i) in menuEntries.filter((entry) => entry.condition?.() ?? true)"
          :key="i"
          :to="item.route != null ? { name: item.route } : undefined"
          exact
          @click="item.onClick != null && item.onClick()"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
  import axios from "axios";
  export default {
    name: "ProfileMenu",
    data() {
      return {
        avatar: null
      };
    },
    computed: {
      isUserConnected() {
        return this.$store.getters.isConnected;
      },
      menuEntries() {
        return [
          {
            text: "My profile",
            icon: "mdi-account",
            route: "profile"
          },
          {
            text: "Recipes",
            icon: "mdi-bookshelf",
            route: "recipes",
            condition: () => {
              return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
            }
          },
          {
            text: "Planning",
            icon: "mdi-calendar-month",
            route: "home",
            condition: () => {
              return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
            }
          },
          {
            text: "Shopping",
            icon: "mdi-cart-variant",
            route: "home",
            condition: () => {
              return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
            }
          },
          {
            text: "Administration",
            icon: "mdi-application-cog-outline",
            route: "admin",
            condition: () => {
              return this.$store.getters.isAdmin;
            }
          },
          {
            text: "Logout",
            icon: "mdi-logout",
            onClick: () => {
              this.$store.commit("removeJwtToken");
              this.$store.commit("setMainColor", null);
              this.$router.push({name: "connection"})
            }
          }
        ];
      }
    },
    watch: {
      isUserConnected() {
        this.refreshAvatar();
      }
    },
    created() {
      this.refreshAvatar();
    },
    methods: {
      refreshAvatar() {
        if (this.isUserConnected) {
          axios.get(`/users/avatar/${this.$store.state.tokenPayload.userId}`).then((response) => {
            this.avatar = response.data.image;
          });
        }
      }
    }
  };
</script>
