<template>
  <v-row class="align-center">
    <v-col
      :cols="12"
      :md="2"
      class="py-0"
    >
      <span class="pe-6">{{ name }}</span>
    </v-col>
    <v-col class="py-0">
      <v-text-field
        class="pe-6"
        type="number"
        placeholder="Amount"
        :value="amount"
        :rules="rules ? rules('value') : []"
        @input="updateAmount"
      />
    </v-col>
    <v-col class="py-0">
      <FetchSelect
        :url="unitsUrl"
        :mapper="e => e.items"
        item-text="name"
        item-value="id"
        clearable
        :value="unitId"
        placeholder="Unit (optionnal)"
        :rules="rules ? rules('unitId') : []"
        @input="$emit('update:unitId', $event)"
      />
    </v-col>
    <v-btn icon>
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
  </v-row>
</template>

<script>
  import FetchSelect from '../FetchSelect.vue';
  export default {
    components: { FetchSelect },
    props: {
      name: {
        type: String,
        required: true
      },
      metricId: {
        type: Number,
        required: true
      },
      amount: {
        type: Number,
        required: false,
        default: null
      },
      unitId: {
        type: Number,
        required: false,
        default: null
      },
      rules: {
        type: Function,
        required: false,
        default: null
      }
    },
    computed: {
      unitsUrl() {
        return `/units/pagination?skip=0&limit=20&columns[]=metric.id&filter=${this.metricId}`;
      }
    },
    methods: {
      updateAmount(value) {
        this.$emit('update:amount', Number.parseFloat(value));
      }
    }
  }
</script>