<template>
  <v-container class="notranslate">
    <v-row>
      <v-col>
        <h2 class="text-center">
          {{ registerMode ? "Registration" : "Login" }}
        </h2>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col
        cols="12"
        md="5"
      >
        <ValidationForm
          :url="authUrl"
          :form="form"
          @submit="sendAuth"
        >
          <template #content="{ rules }">
            <v-container>
              <v-row v-if="registerMode">
                <v-col>
                  <v-text-field
                    v-model="form.nickname"
                    label="Nickname"
                    :rules="rules('nickname')"
                    :error-count="3"
                    required
                  />
                </v-col>
              </v-row>
              <v-row v-if="registerMode">
                <v-col>
                  <v-text-field
                    v-model="form.firstName"
                    label="Firstname"
                    :rules="rules('firstName')"
                    :error-count="3"
                    required
                  />
                </v-col>
              </v-row>
              <v-row v-if="registerMode">
                <v-col>
                  <v-text-field
                    v-model="form.lastName"
                    label="Lastname"
                    :rules="rules('lastName')"
                    :error-count="3"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.email"
                    label="Email"
                    :rules="rules('email')"
                    :error-count="3"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="form.password"
                    label="Password"
                    type="password"
                    :rules="rules('password')"
                    :error-count="3"
                    required
                  />
                </v-col>
              </v-row>
              <v-row v-if="registerMode">
                <v-col>
                  <v-text-field
                    v-model="form.confirmPassword"
                    label="Confirm password"
                    type="password"
                    :rules="confirmPasswordRule"
                    :error-count="3"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <router-link :to="authRoute">
                    {{ authSwitchLink }}
                  </router-link>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-btn
                    class="primary"
                    type="submit"
                    :disabled="confirmPasswordRule.length != 0"
                  >
                    {{ registerMode ? "Create Account" : "Login" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </ValidationForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ValidationForm from "@/components/ValidationForm";
  import axios from 'axios';

  export default {
    name: "AuthenticationView",
    components: {
      ValidationForm
    },
    data() {
      return {
        form: {
          email: null,
          password: null
        }
      };
    },
    computed: {
      registerMode() {
        return this.$route.meta?.register ?? false
      },
      authSwitchLink() {
        return this.registerMode ? 
          "Already a member ? Connect to your account instead"
          : "Not a member yet ? Create an account instead"
      },
      authRoute() {
        return this.registerMode ?
          {name: "connection"}
          : {name: "register"}
      },
      authUrl() {
        return this.registerMode ? "/register" : "/login"
      },
      confirmPasswordRule() {
        if (this.registerMode && this.form.password != this.form.confirmPassword)
          return [() => "Passwords does not match"];
        return [];
      }
    },
    watch: {
      registerMode() {
        this.resetRegisterForm();
      }
    },
    created() {
      this.resetRegisterForm();
    },
    methods: {
      sendAuth(resp) {
        if (![200, 201].includes(resp.status)) {
          return;
        }

        if (this.registerMode) {
          this.$router.push({ name: "connection" });
        } else {
          this.$store.commit("setJwtToken", resp.headers["authorization"]);
          axios.get("/users/current").then(resp => {
            this.$store.commit("setMainColor", resp.data.user.preferences.mainColor);
          });
          this.$router.push({ name: "home" });
        }
      },
      
      resetRegisterForm() {
        if (!this.registerMode) {
          delete this.form.nickname;
          delete this.form.firstName;
          delete this.form.lastName;
          delete this.form.confirmPassword;
        } else {
          this.form = {
            ...this.form,
            nickname: null,
            firstName: null,
            lastName: null,
            confirmPassword: null
          }
        }
      }
    }
  };
</script>
