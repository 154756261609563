<template>
  <div class="my-4">
    <v-navigation-drawer
      app
      clipped
      permanent
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Administration
          </v-list-item-title>
          <v-list-item-subtitle> Manage application </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        items: [
          { title: "Ingredients", icon: "mdi-food-apple", route: { name: "manage-ingredients" } },
          { title: "Units", icon: "mdi-weight-kilogram", route: { name: "manage-units" } }
        ]
      };
    }
  };
</script>
