<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2 class="text-center">
          Ingredients Management
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PaginatedTable
          ref="ingredientsTable"
          count-url="/ingredients/count"
          pagination-url="/ingredients/pagination"
          :headers="headers"
          @add-item="addIngredient"
          @edit-item="editIngredient"
          @delete-item="deleteIngredient"
        />
        <IngredientForm
          v-model="createDialog"
          @submit="refreshIngredients"
        />
        <IngredientForm
          v-model="editDialog"
          :edit-mode="true"
          :default="editingIngredient"
          @submit="refreshIngredients"
        />
        <DeleteIngredient
          v-if="deletingIngredient != null"
          v-model="deleteDialog"
          :ingredient="deletingIngredient"
          @submit="refreshIngredients"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import PaginatedTable from "@/components/PaginatedTable.vue";
  import IngredientForm from "@/components/IngredientManagementView/IngredientForm.vue";
  import DeleteIngredient from "@/components/IngredientManagementView/DeleteIngredient.vue";
  import axios from 'axios';

  export default {
    components: {
      DeleteIngredient,
      IngredientForm,
      PaginatedTable
    },

    data() {
      return {
        createDialog: false,
        editDialog: false,
        editingIngredient: null,
        deleteDialog: false,
        deletingIngredient: null,
        defaultUnits: []
      };
    },

    computed: {
      headers() {
        return [
          {
            text: "Ingredient",
            align: "start",
            value: "name",
            sortable: false,
            searchable: true
          },
          { text: "Metric", value: "metric.name", sortable: false, searchable: true },
          { text: "Unitary value", value: "unitaryValue", sortable: false, searchable: false, formatter: ({item, value}) => value != null ? `${value}${this.getDefaultUnitSymbol(item.metric)}` : value }
        ];
      }
    },

    created() {
      axios.get("/units/default/all").then(resp => {
        this.defaultUnits = resp.data.units;
      });
    },

    methods: {
      addIngredient() {
        this.createDialog = true;
      },
      editIngredient(ingredient) {
        this.editingIngredient = ingredient;
        this.editDialog = true;
      },

      deleteIngredient(ingredient) {
        this.deletingIngredient = ingredient;
        this.deleteDialog = true;
      },
      
      refreshIngredients() {
        this.$refs.ingredientsTable.updateItems();
      },

      getDefaultUnitSymbol(metric) {
        return this.defaultUnits.find(unit => unit.metric.id === metric.id)?.symbol ?? "";
      }
    }
  };
</script>
